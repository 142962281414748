import config from '../config';
import axios from 'axios';
//import utils from './utils';
import eventJson from '../eventTest.json';//'../eventTest_nodata.json'
import Log from './Log';

const HTTPStatusCodes = { 'OK': 200, 'MULTIPLE_CHOICES': 300, 'NOT_FOUND': 404 };

var errorStatus = 0;

/**
* This is the asyncron axios api call method
* 
* @class: axios api
* 
*/
export default axios.create({
    baseURL: config.API_GRAPHQL_BASE_URL,//config.API_BASE_URL,
    timeout: config.API_TIMEOUT,
    responseType: 'json',
    withCredentials: false,
    //maxContentLength: 2000,
    //httpAgent: new http.Agent({ keepAlive: true }),
    //httpsAgent: new https.Agent({ keepAlive: true }),
    ////validateStatus: (status: HTTPStatusCodes) => {
    validateStatus: (status) => {
        errorStatus = status;
        return status >= HTTPStatusCodes.OK && status < HTTPStatusCodes.MULTIPLE_CHOICES;
    },
    transformResponse: [function (resp) {
        //if(errorStatus >= HTTPStatusCodes.OK && errorStatus < HTTPStatusCodes.MULTIPLE_CHOICES) {
        //if(utils.testJSON(resp)) {
        Log.error('API RETURN1', resp);
        ////const rawjson = JSON.parse(resp);
        const rawjson = resp || {};
        var error = rawjson.errors || {};
        error.status = errorStatus;
        var data = rawjson.data || {};
        if(config.DEBUG_DUMMYDATA) {
            data = eventJson.data;
            Log.info('USE DUMMY DATA', data);
        }
        data.device = data.device || {};
        data.device.template = data.device.template || { design: { title: '', mode: '', number: '1' }, bgImg: { description: '', srcUrl: '', tags: '' }, logo: { title: '', srcUrl: '' } };
        data.device.template.bgImg = data.device.template.bgImg || { description: '', srcUrl: '', tags: '' };
        data.device.template.bgImg.srcUrl = data.device.template.bgImg.srcUrl || '';
        data.device.bgUrl = data.device.template.bgImg.srcUrl || '';
        data.device.template.logo = data.device.template.logo || { description: '', srcUrl: '', tags: '' };
        data.device.template.logo.srcUrl = data.device.template.logo.srcUrl || '';
        data.device.logoUrl = data.device.template.logo.srcUrl || '';
        data.device.room = data.device.room || { name: '' };
        data.device.emaIcons = data.device.emaIcons || {};
        
        var events = data.device.events;// || {};
        if(!Array.isArray(events)) {
            let events_temp = [];
            events_temp.push(events);
            events = events_temp;
            data.device.events = events;
        }
        if(typeof data.device.events[0] !== 'undefined') {
            data.device.events.forEach((event) => {
                event.logo = event.logo || {srcUrl: ''};
                return event;
            });
        }
        //Log.error('API RETURN3', data);
        return {data, error};
    }],
    //method: 'get',
    //headers: {'X-Custom-Header': 'foobar'}
});
