/** 
 * This JavaScript file contains the entrypoint of the app
 * 
 * @projectname In Touch DS EventManagmentApp
 * @version 1.0
 * @author TRIAX
 * @copyright 2019
 * 
 */
import Log from './lib/Log';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

Log.info('DEBUG ON');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
