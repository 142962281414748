import debug from 'debug';
import config from '../config';

const BASE = 'ds-event-app';
const COLOURS = {
    trace: 'aqua',
    info: 'teal',
    warn: 'yellow',
    error: 'red'
};

class Log {
    generateMessage(level, message, source) {
        const namespace = `${BASE}:${level}`;
        const createDebug = debug(namespace);

        createDebug.color = COLOURS[level];

        if(source) {
            createDebug(source, message);
        } else {
            createDebug(message);
        }
    }
    
    trace(message, source) {
        return (config.DEBUG && config.DEBUG_LEVEL >= 3) ? this.generateMessage('trace', message, source) : '';
    }
    
    info(message, source) {
        return (config.DEBUG && config.DEBUG_LEVEL >= 2) ? this.generateMessage('info', message, source) : '';
    }
    
    warn(message, source) {
        return (config.DEBUG && config.DEBUG_LEVEL >= 1) ? this.generateMessage('warn', message, source) : '';
    }
    
    error(message, source) {
        return (config.DEBUG && config.DEBUG_LEVEL >= 0) ? this.generateMessage('error', message, source) : '';
    }
}

export default new Log();
