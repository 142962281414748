//import config from '../config';
//import Log from "./Log";

/**
 * Add a leading number zero.
 * 
 * @function: addZero
 *
 * @param {number} i This is a positive number smaller then 10
 * @returns {string} Number with two chars
 * 
 */
var addZero = function(i) {
    if(i < 10) {
        i = '0' + i;
    };
    return i;
};

/**
 * Convert a datetime to a formated datetime.
 * 
 * @function: getDatetime
 *
 * @param {string} value Command which dateformat i will get back
 * @param {date} datetime The datetime which should be conferted
 * @returns {object} Datetimeobject
 * 
 */
var getDatetime = function(value, datetime) {
    if(!datetime) {
        datetime = new Date();
    } else {
        datetime = new Date(datetime);
    }
    var ret = '', year, month, day, hrs, min;//, sec;
    switch(value) {
        case 'date':
            year = String(datetime.getFullYear());
            month = addZero(datetime.getMonth() + 1);
            day = addZero(datetime.getDate());
            ret = year + '-' + month + '-' + day;
            break;
        case 'time':
            hrs = datetime.getHours();
            min = datetime.getMinutes();
            //sec = datetime.getSeconds();
            ret = addZero(hrs) + ':' + addZero(min);// + ':' + addZero(sec);
            break;
        case 'datetime':
            year = String(datetime.getFullYear());
            month = addZero(datetime.getMonth() + 1);
            day = addZero(datetime.getDate());
            hrs = datetime.getHours();
            min = datetime.getMinutes();
            //sec = datetime.getSeconds();
            ret = day + '.' + month + '.' + year + ' ' + addZero(hrs) + ':' + addZero(min);
            break;
        case 'filedate':
            year = String(datetime.getFullYear());
            month = datetime.getMonth() + 1;
            day = datetime.getDate();
            ret = day + '_' + month + '_' + year;
            break;
        case 'en-US':
        case 'en-UK':
        case 'de-DE':
            var options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
            ret = datetime.toLocaleDateString(value, options);
            break;
        default:
            ret = datetime;
    }

    return ret;
};

/**
 * Check if a class is in an element.
 * 
 * @function: hasClass
 *
 * @param {object} el The dom element object
 * @param {object} cls The class
 * @returns {object} The DOM element object
 * 
 */
var hasClass = function(el, cls) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    return reg.test(el.className);
};

/**
 * Check a json if it is a consistent object or a text.
 * 
 * @function: testJSON
 *
 * @param {object} text The json object or string
 * @returns {boolean} true or false
 * 
 */
var testJSON = function(text) {
    try {
        if(typeof text !== "string") {
            return false;
        } else {
            JSON.parse(text);
            return true;                            
        }
    }
    catch(error) {
        return false;
    }
};

/**
 * Check if a property object is equivalent to another object.
 * 
 * @function: isEquivalent
 *
 * @param {object} a The first object
 * @param {object} b The second object
 * @returns {boolean} true or false
 * 
 */
function isEquivalent(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    if(aProps.length !== bProps.length) {
        return false;
    }

    for(var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        if(a[propName] !== b[propName]) {
            return false;
        }
    }

    return true;
}

/**
 * Give the datetime object back. If no datetime given than get the current datetime back.
 * 
 * @function: getDate
 *
 * @param {string} value The optional datetime string
 * @returns {object} Datetimeobject
 * 
 */
var getDate = function(value) {
    var datetime;
    //var datetime2;
    if(value) {
        datetime = new Date(value);
        //datetime2 = Date.parse(value);
        //Log.info('DATE DATETIME0', value);
        //Log.info('DATE DATETIME1', datetime);
        //Log.info('DATE DATETIME2', datetime2);
    } else {
        //datetime = new Date();
        //TODO:
        datetime = +(Date.now());
        datetime = new Date(datetime);
        //datetime = new Date().getTime();
        //datetime.setTime(Date.now());
        
        //Log.info('DATE NEW', datetime);
    }
    return datetime;
}

/**
 * Convert a ISO8601 to a useable string without offset part (Z...).
 * 
 * @function: convertISOString
 *
 * @param {string} value The datetime iso string
 * @returns {string} Datetimestring
 * 
 */
var convertISOString = function(value) {
    var datetime = null;
    if(value) {
        //TODO:
        if(value.indexOf('Z') > 0 && value.indexOf('.') > 0) {
            datetime = value.split('.')[0];
            //Log.info('DATE CONVERT0', datetime);
        } else if(value.indexOf('.') > 0) {
            //datetime = new Date(value).toISOString().split('.')[0];
            datetime = value.split('.')[0];
            //Log.info('DATE CONVERT1', datetime);
        } else {
            datetime = value;
            //Log.info('DATE CONVERT2', datetime);
        }
        //Log.info('DATE CONVERT', datetime);
    }
    return datetime;
}

/**
 * Give the current datetime back as a own defined object.
 * 
 * @function: getUTCDateJSON
 *
 * @returns {object} Object with date and time parmeters
 * 
 */
var getUTCDateJSON = function() {
    const date = new Date();
    const dateWtime = date.toISOString().split("T");
    const time = dateWtime[1].split(".")[0];
    return {
        date: dateWtime[0],
        time: time,
        month: date.getMonth(),
        year: date.getFullYear(),
        monthday: date.getDate(),
        weekday: date.getDay(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        uts: date.getTime()
    };
}

/**
 * Give the difference between two datetime back.
 * 
 * @function: diffTime
 *
 * @param {string} date1 The datetime string
 * @param {string} date2 The datetime string
 * @returns {object} Datetimeobject with some variables
 * 
 */
var diffTime = function(date1, date2) {
    var firstDate;
    var secondDate;
    if(date1) {
        firstDate = new Date(date1);
    } else {
        firstDate = new Date();
    }
    if(date2) {
        secondDate = new Date(date2);
    } else {
        secondDate = new Date();
    }

    var firstStamp = firstDate.getTime();
    var secondStamp = secondDate.getTime();
    var diffAll = Math.round((secondStamp - firstStamp) / 1000);
    var diff = Math.round((secondStamp - firstStamp) / 1000);
    if(!diff) return {};

    let d = Math.floor(diff / (24 * 60 * 60));
    let hh = Math.floor(diff / (60 * 60));
    diff = diff - (d * 24 * 60 * 60);
    let h = Math.floor(diff/(60 * 60));
    diff = diff - (h * 60 * 60);
    let m = Math.floor(diff / (60));
    diff = diff - (m * 60);
    let s = diff;
    var time = hh + ":" + m + ":" + s;
    
    return {
        time: time,
        days: d,
        hour: h,
        minute: m,
        seconds: s,
        utsDiff: diffAll
    };
}

/**
 * Split the datetime into date and time and give both back as a object.
 * 
 * @function: stripTimeFromDate
 *
 * @param {string} date The datetime string
 * @returns {object} Datetimeobject
 * 
 */
var stripTimeFromDate = function(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * Check if two datetimes are equil.
 * 
 * @function: sameDate
 *
 * @param {string} date1 The datetime string
 * @param {string} date2 The datetime string
 * @returns {boolean} true or false
 * 
 */
var sameDate = function(date1, date2) {
    return stripTimeFromDate(date1).getTime() === stripTimeFromDate(date2).getTime();
}

/**
 * Check if the current date is between two datetimes.
 * 
 * @function: betweenDates
 *
 * @param {string} from The first datetime string
 * @param {string} to The last datetime string
 * @param {string} check The current datetime string
 * @returns {boolean} true or false
 * 
 */
var betweenDates = function(from, to, check) {
    var fDate,lDate,cDate;
    fDate = Date.parse(from);
    lDate = Date.parse(to);
    cDate = Date.parse(check);

    if((cDate <= lDate && cDate >= fDate)) {
        //console.log("DATES1", fDate, lDate, cDate);
        return true;
    }
    //console.log("DATES0", fDate, lDate, cDate);
    return false;
}

/**
 * Check if the current date is between two days.
 * 
 * @function: betweenDays
 *
 * @param {string} from The first datetime string
 * @param {string} to The last datetime string
 * @param {string} check The current datetime string
 * @returns {boolean} true or false
 * 
 */
var betweenDays = function(from, to, check) {
    var fDate,lDate,cDate;
    fDate = stripTimeFromDate(from).getTime();
    lDate = stripTimeFromDate(to).getTime();
    cDate = stripTimeFromDate(check).getTime();

    if((cDate <= lDate && cDate >= fDate)) {
        //console.log("DATES1", fDate, lDate, cDate);
        return true;
    }
    //console.log("DATES0", fDate, lDate, cDate);
    return false;
}

/**
 * Sort the wayfinder icons.
 * 
 * @function: sortIcons
 *
 * @param {string} eventRoomId
 * @param {array} emaIcons
 * @returns {array} of emaIcons
 * 
 */
var sortIcons = function(eventRoomId, emaIcons) {
    //TODO: check roomGroups / emaIcons != null
    if(Object.keys(emaIcons).length !== 0) {
        return emaIcons.filter((icon) => Array.isArray(eventRoomId) ? icon.roomId === eventRoomId[0] : icon.roomId === eventRoomId).sort((a, b) => (a.order - b.order));
    } else {
        return {};
    }
}

/**
 * Split the url and give back the icon name.
 * 
 * @function: splitIconSrcUrl
 *
 * @param {string} icon url
 * @returns {array} emaIcons name
 * 
 */
var splitIconSrcUrl = function(url) {
    if(url) {
        var urlArr = url.split('/');
        var title = urlArr[urlArr.length - 1].split('.');
        return title[0];
    } else {
        return '';
    }
}

export default {
    addZero,
    getDatetime,
    hasClass,
    testJSON,
    isEquivalent,
    getUTCDateJSON,
    diffTime,
    getDate,
    convertISOString,
    stripTimeFromDate,
    sameDate,
    betweenDates,
    betweenDays,
    sortIcons,
    splitIconSrcUrl
};
