import React, { Component } from 'react';
import Img from 'react-image';
import Clock from 'react-live-clock';
import './Design2.scss';
import config from '../../../config';
//import utils from '../../../lib/utils';
//import Log from '../../../lib/Log';
//import roomImage from '../../../images/room.svg';

var eventImage = (logoUrl) => {
  return <Img className="event-logo" src={logoUrl} />;
};

var templateImage = (logoUrl) => {
  return <Img className="template-logo" src={logoUrl} />;
};

/**
 * The design2 doorsign screen. Is the second available design.
 * 
 * @class: Design
 * 
 */
export default class Design extends Component {
    /**
     * constructor
     * @param {object} props
     */
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    renderEvents() {
        var stylesEntry = {
            backgroundColor: this.props.device.template.entryBgColor || 'transparent',
            color: this.props.device.template.entryTextColor || '#000000'
        };
        var styles = {
            mainbg: {
                fill: this.props.device.template.entryTextColor || '#000000',
            }
        };

        return this.props.events.map(event => {
            //Log.error('EVENT OUTPUT', event);
            return (
                <div className="trx entry" key={event.id} style={stylesEntry}>
                  <div className="trx-1 center">
                    <div className="eventBrand">
                      {eventImage(event.logoUrl)}
                    </div>
                    <div className="roomNameContainer">
                      <div className="roomName">
                      <svg xmlns="http://www.w3.org/2000/svg" style={styles.mainbg} className="roomPic" width="14" height="20" viewBox="0 0 14 20"><path d="M7,0C3.1,0,0,3.1,0,7c0,5.2,7,13,7,13s7-7.8,7-13C14,3.1,10.9,0,7,0z M7,9.5C5.6,9.5,4.5,8.4,4.5,7S5.6,4.5,7,4.5
  S9.5,5.6,9.5,7S8.4,9.5,7,9.5z"/></svg>{/*<Img style={styles.mainbg} className="roomPic" src={roomImage} />*/}
                      <div className="roomTXT">{event.roomName}</div>
                      <div className="roomTIME">{event.startTimeStr + ' - ' + event.endTimeStr}</div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="title">{event.title}</div>
                    <div className="description">{event.description}</div>
                  </div>
                </div>
            );
        });
    }

    deviceInfo() {
        var dev = this.props.device;
        var stylesClock = {
            /*backgroundColor: this.props.device.template.bgColor,*/
            color: this.props.device.template.textColor || '#555555'
        };
        return (
            <div className="trx header">
              <div className="trx-1-3 component-logo">
                {templateImage(dev.template.logo.srcUrl)}
              </div>
              <div className="trx-2-3 clock" style={stylesClock}>
                <Clock id="clock" format={'DD.MM.YYYY | HH:mm'} ticking={true} interval={1000 * 60} date={config.DEBUG_TIME} />
              </div>
            </div>
        );
    }

    shouldComponentUpdate(props) {
        //Log.error('shouldComponentUpdate0', props);
        //Log.error('shouldComponentUpdate1', this.props);
        //Log.error('shouldComponentUpdate2', utils.isEquivalent(this.props, props));
        //return props.first !== this.props.first || props.last !== this.props.last;
        //if(utils.isEquivalent(this.props, props)) {
        if(this.props === props) {
            //Log.trace('THIS', this);
            return false;
        } else {
            //Log.trace('UPDATE', this);
            return true;
        }
    }

    render() {
        let eventList;
        if(Array.isArray(this.props.events) && this.props.events.length) {
            eventList = (
                        <div id="banquetPortalUl" className="eventArea">
                            {this.renderEvents()}
                        </div>
                        );
        } else {
            /* IDLE PAGE */
            eventList = <div>NO DATA</div>;
        }

        var stylesMain = {
            backgroundColor: this.props.device.template.bgColor,
            color: this.props.device.template.textColor
        };

        return (
            <div id='ds-d2' className="container" style={stylesMain}>
                {this.deviceInfo()}
                {eventList}
            </div>
        );
    }
}
