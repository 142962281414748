import React, { Component } from 'react';
import Img from 'react-image';
import './Design2.scss';
import './Design-icn.scss';
import Clock from 'react-live-clock';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick-theme.scss';
import config from '../../../config';
import utils from '../../../lib/utils';
//import Log from '../../../lib/Log';
//import roomImage from '../../../images/room.svg';

var eventImage = (logoUrl) => {
    var imgStyle = {};
    return <Img className="event-logo" src={logoUrl} alt="LOGO" style={imgStyle} />;
};

var templateImage = (logoUrl) => {
    return <Img className="template-logo" src={logoUrl} />;
};

var viewReady = '1';

/**
 * The design2 overview screen. Is the second available design.
 * 
 * @class: Design
 * 
 */
export default class Design extends Component {
    /**
     * constructor
     * @param {object} props
     */
    constructor(props) {
        super(props);
        this.state = {
            viewMoving: '0'
        };
    }

    showIcons(pos, roomIcons, styles) {
        return <div className={`icon ${typeof roomIcons[pos - 1] != 'undefined' ? roomIcons[pos - 1].content.name : 'trx_icn_0'}`} style={styles.icon}></div>;
    }

    renderEvents() {
        var stylesEntry = {
            backgroundColor: this.props.device.template.entryBgColor || 'transparent',
            color: this.props.device.template.entryTextColor || '#333333'
        };
        var styles = {
            mainbg: {
                fill: this.props.device.template.entryTextColor || '#0e0e0e',
            },
            icon: {
                color: this.props.device.template.entryIconColor || '#777777'
            }
        };

        return this.props.events.map(event => {
            var roomIcons = utils.sortIcons(event.roomId || event.roomIds, this.props.device.emaIcons);

            return (
                <div className="sliderContainer" key={event.id}>
                    <div className="trx entry" style={stylesEntry}>
                        <div className="trx-1-3 left">
                            {eventImage(event.logoUrl)}
                        </div>
                        <div className="trx-2-3 right">
                          <div className="trx details">
                              <div className="trx-3-5 roomName"><svg xmlns="http://www.w3.org/2000/svg" style={styles.mainbg} className="roomPic" width="14" height="20" viewBox="0 0 14 20"><path d="M7,0C3.1,0,0,3.1,0,7c0,5.2,7,13,7,13s7-7.8,7-13C14,3.1,10.9,0,7,0z M7,9.5C5.6,9.5,4.5,8.4,4.5,7S5.6,4.5,7,4.5
  	S9.5,5.6,9.5,7S8.4,9.5,7,9.5z"/></svg>{/*<Img style={styles.mainbg} className="roomPic" src={roomImage} />*/} {event.roomName}</div>
                              <div className="trx-2-5 eventTime">{event.startTimeStr + ' - ' + event.endTimeStr}</div>
                          </div>
                          <div className="title">{event.title}</div>
                          <div className="description">{event.description}</div>
                          <div className="trx-1-2 icons">
                            {this.showIcons(1, roomIcons, styles)}
                            {this.showIcons(2, roomIcons, styles)}
                            {this.showIcons(3, roomIcons, styles)}
                          </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    deviceInfo() {
        var dev = this.props.device;
        var stylesClock = {
            /*backgroundColor: this.props.device.template.bgColor,*/
            color: this.props.device.template.textColor || '#555555'
        };
        return (
            <div className="trx header">
              <div className="trx-1-3 component-logo">
                {templateImage(dev.template.logo.srcUrl)}
              </div>
              <div className="trx-2-3 clock" style={stylesClock}>
                <Clock id="clock" format={'DD.MM.YYYY | HH:mm'} ticking={true} interval={1000 * 60} date={config.DEBUG_TIME} />
              </div>
            </div>
        );
    }

    componentWillUnmount() {
        //Log.error('componentWillUnmount', this.state);
        clearInterval(this.waitMovingView);
    }

    UNSAFE_componentWillUpdate() {
        //Log.error('componentWillUpdate', this.state);
    }

    UNSAFE_componentWillReceiveProps(props) {
        //Log.error('componentWillReceiveProps', props);
        //TODO: check props with state for changes
        //if(props.first !== this.props.first || props.last !== this.props.last) {
            this.waitMovingView = setInterval(() => {
                //Log.error('UPDATE TIMEOUT', viewReady);
                if(viewReady === '1') {
                    //Log.error('UPDATE!', viewReady);
                    clearTimeout(this.waitMovingView);
                    this.forceUpdate();
                }
            }, 100);
        //}
    }

    componentDidUpdate() {
        //Log.error('componentDidUpdate', this.state);
        clearInterval(this.waitMovingView);
    }

    shouldComponentUpdate(props) {
        //Log.error('shouldComponentUpdate', props);
        //return props.first !== this.props.first || props.last !== this.props.last;
        return false;
    }

    render() {
        let eventList;
        var settings = {
            dots: true,
            className: 'slickSlider',
            dotsClass: 'slick-dots',
            infinite: true,
            autoplay: true,
            draggable: false,
            accessibility: false,
            pauseOnHover: false,
            arrows: false,
            fade: false,
            autoplaySpeed: 10000,//config.SLIDER_SPEED,
            speed: 500,
            rows: 2,
            slidesPerRow: 3,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            lazyLoad: 'ondemand',
            //vertical: true,
            //verticalSwiping: true,
            beforeChange: function(currentSlide, nextSlide) {
                //Log.error('BEFORE SLIDEc', currentSlide);
                //Log.error('BEFORE SLIDEn', nextSlide);
                viewReady = '0';
            },
            afterChange: function(currentSlide) {
                //Log.error('AFTER SLIDE', currentSlide);
                viewReady = '1';
            },

            responsive: [
                {
                  breakpoint: 1920,
                  settings: {
                    rows: 2,
                    slidesPerRow: 3
                  }
                },
                {
                  breakpoint: 1280,
                  settings: {
                    rows: 2,
                    slidesPerRow: 3
                  }
                },
                {
                  breakpoint: 1080,
                  settings: {
                    rows: 2,
                    slidesPerRow: 2
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    rows: 2,
                    slidesPerRow: 3
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    rows: 2,
                    slidesPerRow: 2
                  }
                },
                {
                  breakpoint: 720,
                  settings: {
                    rows: 2,
                    slidesPerRow: 2
                  }
                }
              ]

        };
        if(Array.isArray(this.props.events) && this.props.events.length) {
            eventList = (
                        <Slider {...settings}>
                            {this.renderEvents()}
                        </Slider>
                        );
        } else {
            /* IDLE PAGE */
            eventList = <div>NO DATA</div>;
        }

        var stylesMain = {
            backgroundColor: this.props.device.template.bgColor,
            color: this.props.device.template.textColor
        };

        return (
            <div id='ov-d2' className="container" style={stylesMain}>
                {this.deviceInfo()}
                {eventList}
                <div id="entryEnd" className="entryEnd"></div>
            </div>
        );
    }
}
